import React from "react";
import ReactDOM from "react-dom"; // Necessary for rendering the app
import "./index.css"; // Import your CSS styles
import App from "./App"; // Import the main App component

// Render the App component into the root element
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root") // Ensure this matches your HTML file
);
