import React from "react";

const Product = ({ title, links }) => {
  return (
    <div className="product">
      <h3>{title}</h3>
      {links.map((link, index) => (
        <a key={index} href={link} target="_blank" rel="noopener noreferrer">
          <button>View Product {index + 1}</button>
        </a>
      ))}
    </div>
  );
};

export default Product;
