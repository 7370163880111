import React from "react";
import "./App.css"; // Make sure to include the CSS file for styles

const MainContentSection = () => {
  return (
    <div className="main-content">
      <div className="hero">
        <h1 className="animate-fade-in">
          Stop Scrolling, Start Saving! Dealaxe brings all your shopping needs
          together.
        </h1>
        <img
          src="image\pexels-photo-555237.jpeg"
          alt="Shopping hero banner"
          className="hero-image"
        />
        <p className="animate-fade-in">
          Search smarter, not harder. Dealaxe makes shopping a breeze!!
        </p>
      </div>

      {/* Problem Section */}
      <div className="problems">
        <h2>Some common problems during online shopping</h2>
        <div className="problem-container">
          <div className="problem">
            <img
              src="image\pixlr-image-generator-deb1ec28-f910-453a-ab9e-60f355e27a92.png"
              alt="Frustration overload due to too many shopping sites"
            />
            <p>Too many shopping sites, not enough time!</p>
            <h3>
              <b>Frustration Overload??</b>
            </h3>
          </div>
          <div className="problem">
            <img
              src="image\different-prices-of-sam.png"
              alt="Price comparison confusion"
            />
            <p>
              Overwhelmed by different prices in different sites "Price wars?
              More like price confusion!"
            </p>
            <h3>
              <b>Price Comparison Chaos??</b>
            </h3>
          </div>
        </div>
        <div className="problem-container">
          <div className="problem">
            <img src="/image/OIP.jpeg" alt="Drowning in product details" />
            <p>Specs, features, reviews... drowning in details!</p>
            <h3>
              <b>Lost in the Details??</b>
            </h3>
          </div>
          <div className="problem">
            <img
              src="/image/COD-Cash-On-Delivery-a-preferred-Payment-Method-on-Your-MoreCustomersApp-Online-Store-in-India.jpg"
              alt="Uncertainty about Cash on Delivery availability"
            />
            <p>
              Uncertainty about Cash on Delivery (COD) availability for specific
              products or stores.
            </p>
            <h3>
              <b>Limited Payment Option Awareness??</b>
            </h3>
          </div>
        </div>
      </div>

      {/* Dealaxe Section */}
      <div className="dealaxe">
        <h2 className="animate-fade-in">
          Dealaxe: Your One-Stop Shopping Hub!
        </h2>
        <p className="animate-slide-up">
          Imagine a world where you don’t have to endlessly search through
          countless apps and websites to find the perfect product at the best
          price. That world is here with Dealaxe! We’re your one-stop shopping
          hub for all things fashion, groceries, and electronics.
        </p>
        <p className="animate-slide-up">
          Dealaxe is your one-stop shopping platform that makes finding the best
          deals easier than ever! Tired of searching through multiple websites
          and apps to find the perfect product at the best price? Dealaxe
          aggregates products from various stores across fashion, groceries, and
          electronics, allowing you to search, compare prices, and find the best
          option with just a few clicks.
        </p>
        <p>Here’s what sets Dealaxe apart:</p>
        <ul>
          <li>
            Effortless Search: Find what you need with our user-friendly search
            bar. No more hopping between apps!
          </li>
          <li>
            Powerful Comparison: Compare prices, COD availability, and delivery
            options at a glance. Find the perfect deal for you.
          </li>
          <li>
            Variety & Choice: Discover a wide range of products from trusted
            stores across your favorite categories.
          </li>
          <li>
            Time-Saving Convenience: Say goodbye to endless browsing. Dealaxe
            puts everything you need in one place.
          </li>
          <li>
            Always Evolving: We’re constantly expanding our reach to bring you
            even more shopping options.
          </li>
        </ul>
      </div>

      {/* Our Services Section */}
      <div className="our-services">
        <h2>Our Services</h2>
        <div className="service-container">
          <div className="service">
            <img
              src="\image\Gemini_Generated_Image_5697s95697s95697.jpeg"
              alt="Centralized shopping platform"
            />
            <h3>Centralized Platform</h3>
            <p>
              Dealaxe will act as a one-stop shop for fashion, groceries, and
              electronics, allowing users to browse and compare products from
              various apps on a single platform. This eliminates the need to
              switch between multiple apps and simplifies the shopping
              experience
            </p>
          </div>
          <div className="service">
            <img
              src="\image\Gemini_Generated_Image_lqo949lqo949lqo9.jpeg"
              alt="Real-time product information"
            />
            <h3>Real-time Information</h3>
            <p>
              Dealaxe would gather product details like price, availability
              (including Cash on Delivery - COD), delivery date, and return
              policy from various sources. This would provide users with a
              comprehensive understanding of each product before making a
              purchase.
            </p>
          </div>
          <div className="service">
            <img
              src="\image\GFl2FVWVSGWfCtBrfEu59g.webp"
              alt="Product comparison engine"
            />
            <h3>Comparison Engine</h3>
            <p>
              Dealaxe could analyze the collected data and recommend the most
              suitable product to users based on their needs and preferences.
              This could involve factors like price, delivery time, COD
              availability, and return policy.
            </p>
          </div>
          <div className="service">
            <img
              src="\image\Yyg0P2-tSeSmACsstRSGOQ.webp"
              alt="Seamless purchase integration"
            />
            <h3>Seamless Purchase Integration</h3>
            <p>
              Dealaxe aims to offer a seamless purchase experience. Ideally,
              users won't need to leave the platform to complete their
              transactions. Dealaxe will integrate with the respective shopping
              apps, allowing users to securely purchase products directly within
              the Dealaxe website.
            </p>
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="why-choose-us">
        <h2>Why Choose Us?</h2>
        <div className="reason-container">
          <div className="reason">
            <h3>Effortless Convenience</h3>
            <p>
              Dealaxe offers a one-stop shop for fashion, groceries, and
              electronics. Instead of juggling multiple apps and websites, users
              can browse and compare products from various sources all on
              Dealaxe. This saves time and simplifies the shopping experience.
            </p>
          </div>
          <div className="reason">
            <h3>Personalized Recommendations</h3>
            <p>
              Dealaxe doesn't just display products; it personalizes the
              shopping journey. By understanding user requirements (budget,
              brands, preferences), Dealaxe recommends products that best suit
              their needs. This eliminates the overwhelm of choice and ensures
              users see the most relevant options.
            </p>
          </div>
          <div className="reason">
            <h3>Informed Decision Making</h3>
            <p>
              Dealaxe goes beyond just showing products. Their in-depth analysis
              provides valuable insights like COD availability, price
              comparisons, delivery timelines, and return policies. This
              empowers users to make informed purchase decisions based on their
              specific needs and preferences.
            </p>
          </div>
          <div className="reason">
            <h3>Seamless Purchase Experience</h3>
            <p>
              In the ideal scenario, Dealaxe would integrate with partner
              shopping apps, allowing users to securely purchase products
              directly within the Dealaxe website.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainContentSection;
