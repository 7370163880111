import React, { useState, useEffect } from "react";
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ProductList from "./ProductList";
import MainContentSection from "./MainContentSection";

const App = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isListening, setIsListening] = useState(false); // To track if speech recognition is active

  const products = [
    {
      title: "Furniture",
      links: [
        "https://www.amazon.in/s?k=furniture&i=todays-deals&crid=2A76QQ9KMPLIQ&sprefix=furniture%2Ctodays-deals%2C376&linkCode=ll2&tag=dealaxe2024-21&linkId=3ea75201046c3ba6171ebfbecd67fb87&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=furniture&crid=2WCEQVX8IC9QB&sprefix=furniture%2Caps%2C313&linkCode=ll2&tag=dealaxe2024-21&linkId=c69a1de1500c558c069f4c367fb7b6cd&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "Electronics",
      links: [
        "https://www.amazon.in/s?k=boat+watches&crid=14U89BTP2GNL8&sprefix=boat+watches%2Caps%2C292&linkCode=ll2&tag=dealaxe2024-21&linkId=1031f854a4849ecab3d8a2e9c68090c5&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/electronics/b?ie=UTF8&node=976419031&linkCode=ll2&tag=dealaxe2024-21&linkId=a8e89fe6265c071b361473afb682625e&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=all+phones&crid=6B8LTZ4NJASY&sprefix=all+phones%2Caps%2C277&linkCode=ll2&tag=dealaxe2024-21&linkId=b9ad162a9843692b33d39ff3764d888d&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=watches&crid=1FVJSWRO0F6NN&sprefix=watches%2Caps%2C426&linkCode=ll2&tag=dealaxe2024-21&linkId=596907cc1be6a6b07ecd621fa342635d&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "Home & Kitchen - Decor",
      links: [
        "https://www.amazon.in/s?k=home+and+kitchen+decor&crid=3SAKCU18GAHCB&sprefix=home+and+kitchen+deco%2Caps%2C303&linkCode=ll2&tag=dealaxe2024-21&linkId=066bf222e9cbf19ba66c72ca07204609&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/l/56989183031?tag=dealaxe2024-21",
      ],
    },
    {
      title: "Home & Kitchen - Appliances",
      links: [
        "https://www.amazon.in/s?k=home+and+kitchen+appliance&crid=1MBGV86KT90HL&sprefix=home+and+kitchen+appliance%2Caps%2C264&linkCode=ll2&tag=dealaxe2024-21&linkId=38973be718a56edc0090fca7399a469d&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=%22Home+%26+Kitchen+-+Appliances%22&crid=2S029H5FGZV52&sprefix=home+%26+kitchen+-+appliances+%2Caps%2C339&linkCode=ll2&tag=dealaxe2024-21&linkId=f2321ee1d8a6f0531b71ee6095c32fe1&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "clothes for men",
      links: [
        "https://www.amazon.in/s?k=formal+wear+for+men&crid=1WS3DCBB8J1CO&sprefix=formal+wear+for+men%2Caps%2C334&linkCode=ll2&tag=dealaxe2024-21&linkId=ced32fe12b7868cc193d2b4e9b004ac8&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=clothes+for+men&crid=24PLZF7XXS5CY&sprefix=clothes%2Caps%2C301&linkCode=ll2&tag=dealaxe2024-21&linkId=45f74e919e83979bf7328d380fd9ff1f&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=track+pant+for+men&crid=TW442WM7R6EA&sprefix=track+pant+for+men+%2Caps%2C478&linkCode=ll2&tag=dealaxe2024-21&linkId=fe78b7783b0e47c2e30d7e8b2151b1d2&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "clothes for kids ",
      links: [
        "https://www.amazon.in/s?k=kids+cloth&linkCode=ll2&tag=dealaxe2024-21&linkId=6f2ccb4fe951b174e0f70ad213aa5723&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=clothes+for+childrens&i=fashion&crid=1QJNQGWF1YFSP&sprefix=clothes+for+childrens%2Cfashion%2C439&linkCode=ll2&tag=dealaxe2024-21&linkId=272d1c356fd2c9457f0eb99b4c0ffcf6&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=clothes+for+childrens+og+age+12-15&i=fashion&crid=2GCMULGZFL4ZM&sprefix=clothes+for+childrens+og+age+12-15%2Cfashion%2C373&linkCode=ll2&tag=dealaxe2024-21&linkId=2279ca53a465992089e70d21577419cb&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "Winter wear for all ",
      links: [
        "https://www.amazon.in/s?k=winter+wear+for+all&crid=BSFTN4O6F826&sprefix=winter+wear+for+all%2Caps%2C390&linkCode=ll2&tag=dealaxe2024-21&linkId=c8be8b9c35a169eed790498b92c0c4d5&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=winter+wear+for+women&crid=1GBCF6MZYRR2Z&sprefix=winter+wear+for+women%2Caps%2C350&linkCode=ll2&tag=dealaxe2024-21&linkId=1288a5c53837aa47984901b3d190727e&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=winter+wear+for+kids&crid=273JQ9AXSLG01&sprefix=winter+wear+for+kid%2Caps%2C404&linkCode=ll2&tag=dealaxe2024-21&linkId=73aed84ca291c9b7164ea2d407f0c069&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=winter+wear+for+mens&crid=2Y1IJ03K5LK2Q&sprefix=winter+wear+for+mens%2Caps%2C499&linkCode=ll2&tag=dealaxe2024-21&linkId=e37ab66fdf4772f8b27d0c720c975365&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "beauty products",
      links: [
        "https://www.amazon.in/s?k=all+beauty+product&i=fashion&crid=239E3DY1KZSIN&sprefix=all+beauty+product%2Cfashion%2C257&linkCode=ll2&tag=dealaxe2024-21&linkId=b4099e0e8bf29cdb81f18a354bd04ae1&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=beauty&i=todays-deals&crid=3EJ35HOWLZBV1&sprefix=beaut%2Ctodays-deals%2C1617&linkCode=ll2&tag=dealaxe2024-21&linkId=8d0b799648abceb9112bd9c5841ebdc4&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=sheet+mask&i=todays-deals&crid=2LDZEV69J04CF&sprefix=sheet+mask%2Ctodays-deals%2C297&linkCode=ll2&tag=dealaxe2024-21&linkId=13ba3affd110e9e00acc29692b0762cf&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=beauty+products+for+women&crid=3H4U0D6K2R2ID&sprefix=beauty%2Caps%2C342&linkCode=ll2&tag=dealaxe2024-21&linkId=ed60cd5437b8a9e2e84760f5e60fd912&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=sunscreen&i=beauty&crid=2FQ8ZNKYV7PCK&sprefix=suns%2Cbeauty%2C393&linkCode=ll2&tag=dealaxe2024-21&linkId=2fcf4eeebf6c303a478f359450ceb6be&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=fragrance&rh=n%3A1374298031&linkCode=ll2&tag=dealaxe2024-21&linkId=62b95547342a3aca7d7d87c1907dfd16&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/mens-grooming-store/b?ie=UTF8&node=5122801031&linkCode=ll2&tag=dealaxe2024-21&linkId=551cfcf9cabf82b925df492fde9a88d2&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=soap&rh=n%3A5122801031&linkCode=ll2&tag=dealaxe2024-21&linkId=3b072dc69f3de75c1ccc52c408f76422&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=maskara%2C+waterproof&i=beauty&crid=3UR1994TJK9D2&sprefix=mask%2Cbeauty%2C555&linkCode=ll2&tag=dealaxe2024-21&linkId=708a3fc34278f13ad8f45ea1e226c7c1&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=face+mask+sheets&i=beauty&crid=3SEVNEV586FJ3&sprefix=face%2Cbeauty%2C335&linkCode=ll2&tag=dealaxe2024-21&linkId=697ca197899941901995d046ee4d212f&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=face+powder+for+glowing+skin&i=beauty&crid=3PY7638A4BMV4&sprefix=face+powder%2Cbeauty%2C334&linkCode=ll2&tag=dealaxe2024-21&linkId=559f05c12737f756cc0d46569eba29b2&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "women wear",
      links: [
        "https://www.amazon.in/s?k=women+wear&crid=1QKXKMKZEBO3A&sprefix=women+wear%2Caps%2C405&linkCode=ll2&tag=dealaxe2024-21&linkId=fff113124301715eeaecb9af687d0c34&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=tops&linkCode=ll2&tag=dealaxe2024-21&linkId=ae1c95bf5a8c1af7d70b462cfd2a1303&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=beach+wear+dress+for+women&i=todays-deals&crid=82SRX5W7DOEM&sprefix=bea%2Ctodays-deals%2C331&linkCode=ll2&tag=dealaxe2024-21&linkId=ab7ba748cb991fbdce4a95f22ab523f3&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=party+wear+kurta+set+for+women+latest+design&i=todays-deals&crid=RXJ0SW9PUMTH&sprefix=party+we%2Ctodays-deals%2C465&linkCode=ll2&tag=dealaxe2024-21&linkId=34fc48537c87156da1e7068cf1102bbc&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=saree&i=todays-deals&crid=JSOOYS3PAPGL&sprefix=sare%2Ctodays-deals%2C320&linkCode=ll2&tag=dealaxe2024-21&linkId=67db7627360e105bca71f296ff8962b4&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=formal+wear+for+women&crid=1U6DXAIMGW93M&sprefix=formal+wear%2Caps%2C390&linkCode=ll2&tag=dealaxe2024-21&linkId=eeafc86780757f41551729bcf002257b&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "phone cover",
      links: [
        "https://www.amazon.in/s?k=phone+cover&i=todays-deals&crid=26HIQCQUV9414&sprefix=phone+cover%2Ctodays-deals%2C328&linkCode=ll2&tag=dealaxe2024-21&linkId=d262c626524acda309b2d4faf480fe95&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/Jkobi-Apple-iPhone-Textured-Protection/dp/B0D177545G?_encoding=UTF8&pd_rd_w=GblwE&content-id=amzn1.sym.509965a2-791b-4055-b876-943397d37ed3%3Aamzn1.symc.fc11ad14-99c1-406b-aa77-051d0ba1aade&pf_rd_p=509965a2-791b-4055-b876-943397d37ed3&pf_rd_r=XZN33H8W4EZSF4BX4P7C&pd_rd_wg=r6lSj&pd_rd_r=efb54550-94d3-4b01-bfd5-8622f44c3342&th=1&linkCode=ll1&tag=dealaxe2024-21&linkId=2966e3ebc0d370587cc7026996c04619&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=phone+covers&i=electronics&crid=2PU8JX5Y7GPO5&sprefix=phone+covers%2Celectronics%2C457&linkCode=ll2&tag=dealaxe2024-21&linkId=db52b292bbbb3fb290db182f34176413&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=i+phone+cover&i=electronics&crid=VUA3KMIEHOC8&sprefix=i+phone+cover%2Celectronics%2C353&linkCode=ll2&tag=dealaxe2024-21&linkId=66cfc235facfaf94f02c3a7453de0b34&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "Sales",
      links: [
        "https://www.amazon.in/events/blackfriday/&ref_=ine_bf24_swm_cgs?_encoding=UTF8&pf_rd_p=96244432-d3a6-489a-b2c0-56cdf54c1606&pf_rd_s=nav-sitewide-msg&pf_rd_t=4201&pf_rd_i=navbar-4201&pf_rd_m=A21TJRUUN4KGV&pf_rd_r=QN2JARJW3SQS1RSX6VHP&discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522&linkCode=ll2&tag=dealaxe2024-21&linkId=3aeb9ef25f6291a58e25b3a82b30e5b7&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "luggage & bags",
      links: [
        "https://www.amazon.in/MOKOBARA-Transit-Backpack-Premium-Luggage/dp/B0C85WC755?th=1&linkCode=ll1&tag=dealaxe2024-21&linkId=a3f5c344435d0289de2eef6477c57bd4&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "health & household supplies",
      links: [
        "https://www.amazon.in/Household-Supplies/b?ie=UTF8&node=1374515031&linkCode=ll2&tag=dealaxe2024-21&linkId=2b5a78a504ee05d8fbbce4aee3d4ca3a&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?rh=n%3A1350384031%2Cn%3A1374515031%2Cn%3A1374539031&dc=&qid=1733044562&rnid=1374515031&linkCode=ll2&tag=dealaxe2024-21&linkId=abaab79f34ab903d164fa73c99364102&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/health-and-personal-care/b?ie=UTF8&node=1350384031&linkCode=ll2&tag=dealaxe2024-21&linkId=5a6713dd62752558c7550bf4fb2dca3d&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "Baby products",
      links: [
        "https://www.amazon.in/gp/bestsellers/baby?&linkCode=ll2&tag=dealaxe2024-21&linkId=b5bfc2d39f2e4f612285cd8103bb8e73&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/b?node=1953115031&linkCode=ll2&tag=dealaxe2024-21&linkId=9a46dc906eee0de3b5003914fa9f449f&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/Activity-Entertainment/b?ie=UTF8&node=1953106031&linkCode=ll2&tag=dealaxe2024-21&linkId=14310081ddfa18698050bd4e5a479248&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "Grocery",
      links: [
        "https://www.amazon.in/s?k=grocery&i=todays-deals&crid=1KTT2IYANR66A&sprefix=grocery%2Ctodays-deals%2C356&linkCode=ll2&tag=dealaxe2024-21&linkId=96a32e647161d852a5cce2e96ba961d0&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=grocery+items&i=grocery&crid=2Z2JNFLJVXLQJ&sprefix=grocery%2Cgrocery%2C353&linkCode=ll2&tag=dealaxe2024-21&linkId=86900db325f2d75be4f887fc222aa1a3&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/Snack-Foods/b?ie=UTF8&node=4859498031&linkCode=ll2&tag=dealaxe2024-21&linkId=6761aee19eb0fd5d6ad19fabb8c84ee5&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/Coffee-Tea-Beverages/b?ie=UTF8&node=4859478031&linkCode=ll2&tag=dealaxe2024-21&linkId=0ec8448c5fcd97fa7c5287c0bcbcda40&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/Herbs-Spices-Seasonings/b?ie=UTF8&node=4859486031&linkCode=ll2&tag=dealaxe2024-21&linkId=3be6a34d640213cf219974721602338f&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/gp/bestsellers/grocery?ie=UTF8&linkCode=ll2&tag=dealaxe2024-21&linkId=c7aa3f7c974541b2046c8da21b010449&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "shoes & sandals",
      links: [
        "https://www.amazon.in/s?k=shoes&i=todays-deals&crid=1MWMA7YL9TA21&sprefix=shoes%2Ctodays-deals%2C369&linkCode=ll2&tag=dealaxe2024-21&linkId=5318591f5026d4821437427af0fb72be&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=heeled+sandals+for+women&crid=FN7IJU8KOEJX&sprefix=heel%2Caps%2C348&linkCode=ll2&tag=dealaxe2024-21&linkId=e74eb31fe134dc4ec84b2d3c3f8b7124&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=sandals+for+man&crid=2TXQFP1LC8R3N&sprefix=san%2Caps%2C314&linkCode=ll2&tag=dealaxe2024-21&linkId=070fd1af85db82b6c9d92614a045ea76&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=sandals+for+women&i=todays-deals&crid=Z6FXW1YB0PJ5&sprefix=sandals+for+women%2Ctodays-deals%2C406&linkCode=ll2&tag=dealaxe2024-21&linkId=505f8d826dfdfad287119260131eb03d&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "photos & background",
      links: [
        "https://www.amazon.in/s?k=photo&crid=SRUBABBFNW62&sprefix=photo%2Caps%2C430&linkCode=ll2&tag=dealaxe2024-21&linkId=372e52550d6ea6fb6f9420a7dc087e58&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=photo+background+hiffin&crid=2R8U795C7UJIN&sprefix=photo+background+hiffin%2Caps%2C242&linkCode=ll2&tag=dealaxe2024-21&linkId=0715467c042cd3137f712cee6bc5da9c&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "Stationery Items",
      links: [
        "https://www.amazon.in/s?k=stationery+items&crid=C02G2L38JQYL&sprefix=stat%2Caps%2C337&linkCode=ll2&tag=dealaxe2024-21&linkId=bafc932e446efd12864862a0d01c0495&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
    {
      title: "Deals",
      links: [
        "https://www.amazon.in/b?node=15325111031&pf_rd_m=AT95IG9ONZD7S&pf_rd_s=slot-2&pf_rd_r=2M60DFR8FRVM0K9ST0SH&pf_rd_t=0&pf_rd_p=5bcaf8c4-3ddc-4962-9690-121ea10400b2&pf_rd_i=deals&discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522&linkCode=ll2&tag=dealaxe2024-21&linkId=5d9296e8a89892a64c5d9d75b21a1c2a&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=hues&linkCode=ll2&tag=dealaxe2024-21&linkId=655fbcf73980aef30f192eddc725423e&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/deals?discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522&linkCode=ll2&tag=dealaxe2024-21&linkId=55999a7dfd314f433ca54b90c6dac226&language=en_IN&ref_=as_li_ss_tl",
        "https://www.amazon.in/s?k=deals&crid=2V7PRG9SVPKAE&sprefix=dea%2Caps%2C370&linkCode=ll2&tag=dealaxe2024-21&linkId=1ddfbbfbaa72c5bc6ac65369ca8b19dc&language=en_IN&ref_=as_li_ss_tl",
      ],
    },
  ];

  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Speech Recognition Setup
  useEffect(() => {
    const recognition = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    recognition.lang = "en-US";
    recognition.interimResults = false;

    recognition.onstart = () => {
      setIsListening(true);
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setSearchTerm(transcript); // Use the spoken words as the search term
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error", event.error);
    };

    window.recognition = recognition;

    return () => {
      if (recognition) {
        recognition.stop();
      }
    };
  }, []);

  const startListening = () => {
    if (window.recognition && !isListening) {
      window.recognition.start();
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault(); // Prevent form submission
    // Add logic for search submission if necessary
  };

  return (
    <div className="App">
      <header className="header">
        <nav className="nav-container">
          <div className="logo" style={{ width: 150, height: 150 }}>
            <img src="\image\DEALAXE.PNG.jpeg" alt="DealAxe Logo" />
          </div>
          <form
            onSubmit={handleSearchSubmit}
            className="search-bar"
            style={{ width: 1000, height: 60, fontSize: 12 }}
          >
            <input
              type="search"
              placeholder="Search products..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button
              type="submit"
              style={{ width: 100, height: 40, fontSize: 18 }}
            >
              Search
            </button>
            <button
              type="button"
              onClick={startListening}
              style={{ width: 150, height: 40, fontSize: 18 }}
            >
              🎤 Voice Search
            </button>
          </form>
          <ul className="nav-links" style={{ fontSize: 18 }}>
            <li>
              <a href="/" style={{ fontSize: 18 }}>
                Home
              </a>
            </li>
            <li className="dropdown">
              <a href="/" style={{ fontSize: 18 }}>
                Categories
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a href="/" style={{ fontSize: 16 }}>
                    Fashion
                  </a>
                </li>
                <li>
                  <a href="/" style={{ fontSize: 16 }}>
                    Groceries
                  </a>
                </li>
                <li>
                  <a href="/" style={{ fontSize: 16 }}>
                    Electronics
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/" style={{ fontSize: 18 }}>
                Login/Sign Up
              </a>
            </li>
            <li>
              <div className="cart-icon">
                <i
                  className="fas fa-shopping-cart"
                  style={{ fontSize: 24 }}
                ></i>
              </div>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <MainContentSection />
        {filteredProducts.length > 0 ? (
          <ProductList products={filteredProducts} />
        ) : (
          <p>No products found.</p>
        )}
      </main>
      <footer className="footer">
        <section className="footer-grid">
          <div className="grid-item">
            <h2>Quick Links</h2>
            <ul>
              <li>
                <a href="/" style={{ fontSize: 16 }}>
                  Home
                </a>
              </li>
              <li>
                <a href="/" style={{ fontSize: 16 }}>
                  Categories
                </a>
              </li>
              <li>
                <a href="\src\about us.html" style={{ fontSize: 16 }}>
                  About Us
                </a>
              </li>
              <li>
                <a href="/" style={{ fontSize: 16 }}>
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="grid-item">
            <h2>Customer Service</h2>
            <ul>
              <li>
                <a href="/" style={{ fontSize: 16 }}>
                  FAQs
                </a>
              </li>
              <li>
                <a href="/" style={{ fontSize: 16 }}>
                  Support
                </a>
              </li>
              <li>
                <a href="/" style={{ fontSize: 16 }}>
                  Return Policy
                </a>
              </li>
              <li>
                <a href="/" style={{ fontSize: 16 }}>
                  Shipping Information
                </a>
              </li>
            </ul>
          </div>
          <div className="grid-item">
            <h2>Social Media</h2>
            <ul>
              <li>
                <a href="/" target="_blank" style={{ fontSize: 16 }}>
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="/" target="_blank" style={{ fontSize: 16 }}>
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="/" target="_blank" style={{ fontSize: 16 }}>
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="grid-item">
            <h2>Contact Us</h2>
            <p>
              Email:{" "}
              <a href="dealaxe2024@gmail.com" style={{ fontSize: 16 }}>
                dealaxe2024@gmail.com
              </a>
            </p>
            <p>Mumbai,Maharashtra,India </p>
          </div>
        </section>
        <div className="footer-bottom">
          <p>&copy; 2024 DealAxe. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default App;
