import React from "react";
import Product from "./Product";

const ProductList = ({ products }) => {
  return (
    <div className="product-list">
      {products.map((product, index) => (
        <Product key={index} title={product.title} links={product.links} />
      ))}
    </div>
  );
};

export default ProductList;
